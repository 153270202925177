<template>
  <div v-if="products.length > 0" class="mb-5 mt-5" style="">

    <div class="main-bg-img dessus"></div>

    <v-row class="mt-5 pt-2">
      <v-col cols="11" sm="10" class="px-0 mx-auto" style="z-index:1!important;">
        <h1 class="d-none">Albums</h1>
        <div class="font-flower" style="font-size:60px;">
          <v-btn @click="goBack()" fab small color="grey darken-3"
                  title="retour à la page précédente">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
          Albums
        </div>

        <v-col cols="12" class="pa-0">
          <v-divider/>
          <h2 style="font-size:40px;"> 
            <v-icon large>mdi-chevron-right</v-icon>
            {{ $route.params.categoryName }}
          </h2>
        </v-col>
        <v-row>
          <template v-for="(product) in products">
            <v-col cols="12" sm="6" md="4" lg="4" 
                  class="pa-5 mt-5 text-center" 
                  v-if="inCategory(product)"
                  :key="product.name">
              <router-link :to="'/product/'+product._id">
                <v-img   class="mx-auto" 
                        :class="product.isCircle ? 'img-rounded' : 'img-squarded'"
                        :aspect-ratio="1/1"
                        :src="baseUrl() + product.image"></v-img>
              </router-link>
              <span class="font-dancing" style="font-size:35px;">{{ product.name }}</span>
              <!-- <br><small>{{ product.description.substr(0, 50) }}...</small> -->
            </v-col>
          </template>
        </v-row>
        
      </v-col>
    </v-row>

    <v-dialog v-model="showProductDialog" v-if="productToShow != null"  
              :fullscreen="$vuetify.breakpoint.width < 700"
              transition="dialog-bottom-transition" max-width="600" scrollable>
      <DialogProduct :product="productToShow"></DialogProduct>
    </v-dialog>

  </div>
</template>


<script>
    import core from '../plugins/core.js'
    import router from '../router/router'
    import DialogProduct from '@/components/main-layout/DialogProduct.vue'
    
    //import { i18n } from 'vue-lang-router';

    export default {
        name: "App",
        components: {
          DialogProduct
        },
        data: function () {
            return {
                products: [],
                categories: [],
                productToShow: false,
                showProductDialog: false,
                flip: false
            };
        },
        mounted: function(){
          this.initData()
          this.$root.$on('closeDialogProduct', () => { this.closeDialogProduct() })
          this.$store.dispatch('app/incStat', '/album')
        },
        methods: {
            
            async initData(){
              await this.$store.dispatch('app/fetchEntities', 
                                { entityType: "product", 
                                  query: { public: true }, 
                                  sort: { 'positionAlbum' : 1 } 
                                })
              this.products = this.$store.state.app.data.product

              this.categories = this.$store.state.app.data.category
              console.log("categories", this.categories)
            },
            openDialogProduct(product){
              this.productToShow = product
              this.showProductDialog = true
            },
            closeDialogProduct(){
              this.productToShow = null
              this.showProductDialog = false
            },
            inCategory(product){
              let found = false
              product.categories.forEach(category => {
                if(category.name == this.$route.params.categoryName) found = true
              })
              return found
            },
            goBack(){ router.back() },
            baseUrl(){ return core.baseUrl() }
        },
        computed:{
        }
    };
</script>

